<template>
    <div>
        <nav-bar title="下级用户" url="/sale"></nav-bar>

        <div class="top">
            <div class="title">
                <div class="title-text">
                    下级用户记录
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
                <span class="add" @click="add">新增用户</span>
            </div>

            <van-list v-model="loading" :finished="finish" finished-text="没有更多了" @load="get_list()">
                <van-cell-group class="group-user-list">
                    <van-cell v-for="(item,index) in list" :key="index" :icon="item.headimgurl"
                        :title="item.nickname + '(' + item.full_name + ')'" :label="'总办理数' + item.total_count + '张'">
                        <template #default>
                            <div>
                                <van-tag type="primary" v-if="item.sale_from_state==1">已绑定</van-tag>
                                <van-tag type="warning " v-else>待确认</van-tag>
                                <van-tag type="danger" @click="unbind(index)" style="margin-left: 10px;">解绑</van-tag>
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-list>

            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>

    </div>
</template>

<script>
    import {
        sale_child,
        sale_child_unbind,
        sale_child_bind,
    } from '@/api/sale.js';
    import {
        common_share
    } from '@/api/common.js';
    import {
        Dialog
    } from 'vant';
    var jweixin = require('jweixin-module');
    export default {
        name: 'child',
        data() {
            return {
                list: [],
                finish: false,
                loading: false,
                signPackage: {},
            }
        },
        mounted() {
            this.get_set();
            this.init_config();
        },
        methods: {
            get_set() {
                common_share({ url: '/sale_child' }).then(res => {
                    this.signPackage = res.data.signPackage;
                });
            },

            init_config() {
                var that = this;
                common_share({ loading: true, url: '/sale_child' }).then(res => {
                    var config = res.data.signPackage;
                    jweixin.config({
                        debug: false,
                        appId: config.appId,
                        timestamp: config.timestamp,
                        nonceStr: config.nonceStr,
                        signature: config.signature,
                        jsApiList: [
                            //需要使用的网页服务接口
                            'checkJsApi', //判断当前客户端版本是否支持指定JS接口
                            'onMenuShareTimeline', //分享给好友
                            'onMenuShareAppMessage', //分享到朋友圈
                            'scanQRCode', //扫描二维码
                        ]
                    });
                });
            },

            get_list() {
                this.loading = true;
                sale_child({
                    start: this.list.length,
                    count: 20,
                }).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.list = this.list.concat(res.data.info);
                        if (res.data.info.length < 20) {
                            this.finish = true; //加载完成
                        }
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

            add() {
                var that = this;
                jweixin.ready(function () {
                    //微信扫一扫
                    jweixin.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                            //开始绑定用户信息
                            sale_child_bind({
                                sale_from_id: result
                            }).then(res2 => {
                                if (res2.code == 1) {
                                    Dialog.alert({
                                        message: res2.msg,
                                    }).then(function () {
                                        that.list = [];
                                        that.get_list();
                                        return false;
                                    });
                                }
                            });
                        },
                        fail: function (err) {
                            alert(JSON.stringify(err));
                        },
                    });
                });
            },


            //解绑
            unbind(index) {
                var that = this;
                Dialog.confirm({
                    title: '操作提示',
                    message: '您确定要解绑【' + that.list[index].nickname + '】）吗？',
                }).then(function (res) {
                    sale_child_unbind({
                        sale_from_id: that.list[index].id,
                    }).then(res => {
                        Dialog.alert({
                            message: res.msg,
                        }).then(function () {
                            that.list = [];
                            that.get_list();
                            return false;
                        });
                    });
                }).catch(function (err) { });
            },

        }
    }
</script>

<style scoped>
    .van-icon__image {
        height: 50px;
        width: 50px;
    }

    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .add {
        color: red;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 2000;
    }
</style>